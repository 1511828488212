import { NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, ElementRef, inject, input, OnInit, output, PLATFORM_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@jsverse/transloco';
import { BrnContextMenuTriggerDirective, BrnMenuTriggerDirective } from '@spartan-ng/ui-menu-brain';
import { skipWhile } from 'rxjs';

import { MenuItem } from '@bto/shared/layout/header/menu-items.type';
import { isMac, normalizeShortcut, setupKeyboardShortcutsHandler } from '@lib/context-menu-wrapper/keyboard.utils';
import { OsShortcutPipe } from '@lib/context-menu-wrapper/os-shortcut.pipe';
import {
  HlmMenuComponent,
  HlmMenuGroupComponent,
  HlmMenuItemDirective,
  HlmMenuShortcutComponent,
} from '@lib/ui-menu-helm/src';

@Component({
  selector: 'bto-context-menu-wrapper',
  templateUrl: './context-menu-wrapper.component.html',
  standalone: true,
  imports: [
    HlmMenuComponent,
    HlmMenuGroupComponent,
    HlmMenuItemDirective,
    HlmMenuShortcutComponent,
    BrnMenuTriggerDirective,
    TranslocoPipe,
    OsShortcutPipe,
    NgTemplateOutlet,
    BrnContextMenuTriggerDirective,
  ],
})
export class ContextMenuWrapperComponent<T extends string> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly platformId = inject(PLATFORM_ID);

  translocoKey = input<string>();
  items = input<MenuItem<T>[]>();
  listenToDocument = input<boolean>(false);
  isLeftButton = input<boolean>(false);
  disabled = input<boolean>(false);

  itemClicked = output<{ code: T; button?: HTMLButtonElement }>();
  isMac = false;

  ngOnInit() {
    this.setupElementListener();
  }

  private setupElementListener() {
    const items = this.items();
    if (!items) {
      return;
    }
    const shortcuts = this.getShortcutsFromMenuItems(items);

    if (!Object.keys(shortcuts).length) {
      return;
    }
    this.isMac = isMac();
    setupKeyboardShortcutsHandler(shortcuts, this.listenToDocument() ? document : this.elementRef.nativeElement)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skipWhile(() => this.disabled())
      )
      .subscribe(code => this.itemClicked.emit({ code }));
  }

  private getShortcutsFromMenuItems(menuItems: MenuItem<T>[], result = {} as Record<string, T>): Record<string, T> {
    return menuItems.reduce((acc, item) => {
      if (item.shortcut) {
        acc[normalizeShortcut(item.shortcut)] = item.code;
      }
      if (item.children) {
        return this.getShortcutsFromMenuItems(item.children, acc);
      }
      return acc;
    }, result);
  }
}
