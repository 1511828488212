@if (disabled()) {
  <ng-container *ngTemplateOutlet="content" />
} @else {
  @if (isLeftButton()) {
    <div class="h-full w-full" [brnMenuTriggerFor]="menu">
      <ng-container *ngTemplateOutlet="content" />
    </div>
  } @else {
    <div class="h-full w-full" [brnCtxMenuTriggerFor]="menu">
      <ng-container *ngTemplateOutlet="content" />
    </div>
  }
}

<ng-template #content>
  <ng-content />
</ng-template>

<ng-template #menu>
  <bto-menu>
    <bto-menu-group>
      @for (item of items(); track item) {
        <button #buttonElement btoMenuItem (click)="itemClicked.emit({ code: item.code, button: buttonElement })">
          {{ item.label ? item.label : (translocoKey() + '.menu.' + item.code | transloco) }}
          @if (item.shortcut) {
            <bto-menu-shortcut>{{ item.shortcut | btoOsShortcut }}</bto-menu-shortcut>
          }
        </button>
      }
    </bto-menu-group>
  </bto-menu>
</ng-template>
