import { Pipe, PipeTransform } from '@angular/core';

import { isMac, splitShortCut } from '@lib/context-menu-wrapper/keyboard.utils';

@Pipe({
  name: 'btoOsShortcut',
  standalone: true,
})
export class OsShortcutPipe implements PipeTransform {
  isMac = isMac();

  transform(value: string): string {
    const { win, mac } = splitShortCut(value);
    return this.isMac ? mac : win;
  }
}
