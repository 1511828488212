import { filter, fromEvent, map } from 'rxjs';

export const isMac = () =>
  /(Mac|iPhone|iPod|iPad)/i.test(
    (navigator as unknown as { userAgentData?: { platform: string } })?.userAgentData?.platform || navigator?.platform
  );

export const splitShortCut = (shortcut: string): { win: string; mac: string } => {
  const [mac, win] = shortcut.includes('|') ? shortcut.split('|') : [shortcut, shortcut];
  return { win, mac };
};

const NORMALIZED_KEY_MAPPER: Record<string, string> = {
  '+': '',
  ' ': '',
  Ctrl: '⌘',
  Shift: '⇧',
  '⌘⇧': '⇧⌘', // normalize shift ctrl order
};

export const normalizeShortcut = (shortcut: string): string => {
  const { win, mac } = splitShortCut(shortcut);

  return Object.entries(NORMALIZED_KEY_MAPPER).reduce(
    (acc, [key, value]) => acc.replaceAll(key, value),
    isMac() ? mac : win
  );
};

const keyMap = new Map(
  Object.entries({
    ' ': '⎵',
    ArrowUp: '↑',
    ArrowDown: '↓',
    ArrowLeft: '←',
    ArrowRight: '→',
    Enter: '↵',
    Escape: '⎋',
  })
);

export const encodeKey = ({ shiftKey, ctrlKey, metaKey, key }: KeyboardEvent): string =>
  `${shiftKey ? '⇧' : ''}${(isMac() ? metaKey : ctrlKey) ? '⌘' : ''}${keyMap.get(key) || key.toUpperCase()}`;

export const setupKeyboardShortcutHandler = <T extends string>(
  shortcut: T,
  element: Document | HTMLElement = document
) => setupKeyboardShortcutsHandler({ [shortcut]: shortcut }, element);

export const setupKeyboardShortcutsHandler = <T extends string>(
  shortcutMap: Record<string, T>,
  element: Document | HTMLElement = document
) =>
  fromEvent<KeyboardEvent>(element, 'keydown').pipe(
    map(event => encodeKey(event)),
    map(encodedKey => shortcutMap[encodedKey]),
    filter(Boolean)
  );
