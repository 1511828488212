import { Component, computed, input } from '@angular/core';
import { lucideChevronRight } from '@ng-icons/lucide';
import { hlm } from '@spartan-ng/ui-core';
import type { ClassValue } from 'clsx';

import { HlmIconComponent, provideIcons } from '@spartan-ng/ui-icon-helm';

@Component({
  selector: 'bto-menu-item-sub-indicator',
  standalone: true,
  providers: [provideIcons({ lucideChevronRight })],
  imports: [HlmIconComponent],
  template: ` <bto-icon size="none" class="w-full h-full" name="lucideChevronRight" /> `,
  host: {
    '[class]': '_computedClass()',
  },
})
export class HlmMenuItemSubIndicatorComponent {
  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() => hlm('inline-block ml-auto h-4 w-4', this.userClass()));
}
